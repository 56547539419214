@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-slate-400 bg-slate-900;
}

label{
  @apply text-sm;
}
input,.input{
  @apply w-full py-1.5 px-2.5 text-slate-100 outline-none shadow-none border border-gray-100 bg-transparent rounded-md h-[45px] bg-slate-900
}
select.input{
  @apply bg-slate-900 text-slate-100
}
.button {
  @apply bg-gray-300 text-slate-900 px-5 py-1.5 rounded-md font-semibold 
}
.input-outer{
  @apply flex flex-col gap-2
}
.form-inp-div{
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* dashboard */
.dashboard-right-part{
  @apply h-[100dvh] ms-auto p-10;
  width: calc(100% - 300px);
}
.sheetupload{
  @apply w-full h-[100px] flex bg-slate-400 rounded-lg relative;
}
.sheetupload input{
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
}